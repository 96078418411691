<template>
    <div class="newcenterSee">
        <div class="header-null"></div>
        <div class="content">
            <div class="text-header row-center">
                <div class="header-item column-center" @click="active(item.id)" v-for="(item,index) in typeList" :key="index">
                    <div :class="(activeNum == item.id ? 'header-select ' : ' ')+'header-img row-center'"><img :src="item.icon_img"/></div>
                    <div :class="(activeNum == item.id ? 'text-select ' : ' ')+'text'">{{ item.name }}</div>
                </div>
            </div>
            <div class="newsdynamics row-center" v-if="headerNewsList.length >= 3 && activeNum == 0">
                <div class="image-news">
                    <img :src="headerNewsList[0].img" class="inage-news"/>
                    <div class="title column-center" style="height: 9rem;">
                        <div class="title-con">
                            <div class="p line-one">{{ headerNewsList[0].add_time }}</div>
                            <div class="p line-one">{{ headerNewsList[0].title }}</div>
                        </div>
                        <el-button type="primary" round  @click="path('/newsCenter/see/'+ headerNewsList[0].id + '?type=' + activeNum)">
                            <img src="@/assets/newscenter/news-arrow.png" class="news-arrow"/>
                            {{lang.project_view}}
                        </el-button>
                    </div>
                </div>
                <div class="right-news  column-center">
                    <div class="news"  v-for="(item,index) in 2" :key="index">
                        <div class="title column-center" style="height: 100%;">
                            <div class="title-con">
                                <div class="p line-one">{{ headerNewsList[item].add_time }} </div>
                                <div class="p line-one">{{ headerNewsList[item].title }}</div>
                                <div class="p-text line-three">{{ headerNewsList[item].description }}</div>
                            </div>
                            <div class="title-btn">
                                <el-button type="primary" round @click="path('/newsCenter/see/'+ headerNewsList[item].id + '?type=' + activeNum)">
                                    <img src="@/assets/newscenter/news-arrow.png" class="news-arrow"/>
                                    {{lang.project_view}}
                                </el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="search">
                <searchLine :isBtnBorder="true" @update-data="searchFun"></searchLine>
            </div>
			<div class="list">
				<div class="type" @click="path('/newsCenter/see/'+ item.id + '?type=' + activeNum)" v-for="(item,index) in articleList.data" :key="index" v-if="articleList.data.length > 0">
					<!-- <img class="type-image" v-if="item.img" :src="item.img" alt="" /> -->
					<div class="type-image" :style="{backgroundImage: `url(${item.img})`}" v-if="item.img"></div>
					<div class="type-image-nodata" :style="{ 'background': 'url('+require('@/assets/no_img.png')+')'}" v-else></div>
					<!-- <img class="type-image" v-else src="@/assets/no_img.png" alt="" /> -->
					<div class="text bold column-center">
                        <div class="text-p line-one">{{ item.title }}</div>
                        <div class="text-p line-one" style="color: #999;font-weight: 400;">{{ item.add_time}}</div>
                    </div>
				</div>
                <div v-if="articleList.data.length == 0" class="column-center" style="width: 100%;margin: 3rem 0;">
					<img class="type-image" src="@/assets/nodata.gif" alt=""/>
					<div style="margin-top: 2rem;">{{lang.project_no_data}}</div>
				</div>
			</div>
			<div class="pagination" v-if="articleList.data.length > 0">
				<el-pagination
				background
				:layout="layout"
				:pager-count="5"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page.sync="articleList.current_page"
				:page-size="articleList.per_page"
				:total="articleList.total">
				</el-pagination>
			</div>
        </div>
    </div>
</template>

<script>
import searchLine from "@/components/searchLine.vue"
import { 
	getArticleCateFormType,
    getArticleList,
    getArticleTopList
} from '@/api/store.js'
import {
		mapState
	} from 'vuex'
	export default {
		name: "newcenterSee",
		computed: {
			...mapState({
			  lang: state => state.user.lang,
			}),
			layout() {
			  return this.isMobile ? 'prev, pager, next' : 'prev, pager, next, jumper'; // 根据isMobile的值动态设置layout属性值。如果isMobile为true，则不显示跳转输入框。
			}
		},
		data() {
			return {
				type: this.$route.params.type,
                currentPage3:1,
                activeNum:0,
                typeList:[{
                    id: 0,
                    name:'全部',
                    icon_img: require("../../assets/newscenter/all.png"),
                }],
                page:1,
                keywords:'',
                headerNewsList: [],
                articleList: {
                    data:[],
                },
				isMobile: false	  
			}
		},
		components:{
			searchLine,
		},
		created() {
			this.activeNum = this.$route.params.type
			this.getArticleCateFormTypeFun()
			this.getArticleTopListFun()
			this.getArticleListFun()
			this.typeList[0].name=this.lang.product_show_all
		},
        beforeRouteUpdate(to, from, next) {
            this.activeNum = to.params.type
			this.keywords = ''
			this.page = 1
			this.getArticleTopListFun()
			this.getArticleListFun()
            next();
        },
		mounted() {
			window.screenWidth = document.body.clientWidth;
			if(window.screenWidth<=768){
				this.isMobile=true
			}else{
				this.isMobile=false
			}
			console.log( this.$route.params.type,' this.$route.params.type,mounte')
		},
		methods:{
			path(routePath){
				 this.$router.push(routePath); 
			},
			getArticleCateFormTypeFun(){
				// 获取分类
                let data = {
                    type_id: 3,
                }
				getArticleCateFormType(data).then(res=>{
					this.typeList = [...this.typeList, ...res.data]
				})
			},
			getArticleTopListFun(){
				// 获取获取惠发动态所有分类文章列表
                let data = {
                    type_id: 3,
                }
				getArticleTopList(data).then(res=>{
					this.headerNewsList = res.data.article_list
				})
			},
			getArticleListFun(){
				// 获取获取惠发动态列表
                let data = {
                    type_id: this.activeNum == 0 ? 3 : '' ,
					cate_id:this.activeNum ? this.activeNum : '',
                    keywords: this.keywords,
                    page: this.page,
                }
				getArticleList(data).then(res=>{
					this.articleList = res.data.article_list
				})
			},
			
			active(num){
				this.activeNum = num
				this.page = 1
                this.getArticleTopListFun()
                this.getArticleListFun()
				// 更新列表数据
			},
			searchFun(e){
				 console.log(e,'e')
				 this.keywords = e
                 this.page = 1
                this.getArticleListFun()
			},
			handleSizeChange(e){
				 console.log(e,'handleSizeChange')
			},
			handleCurrentChange(e){
				this.page = e
                this.getArticleListFun()
			},
			path(routePath){
				 this.$router.push(routePath); 
			}
		}


	}
</script>

<style lang="less" scoped>
::v-deep.el-button.is-round {
    border-radius: 2rem;
    padding: 0.5rem 1.25rem;
}
    .newcenterSee{
		background: #f2f2f2 url('../../assets/news_detail_bj.png') no-repeat;
		background-size: 100% auto;
		padding-bottom: 2rem;
        width: 100%;
        margin: 0 auto;
        .content{
            width: 75rem;
            margin: 0 auto;
            .text-header{
                width: 100%;
                background: #fff;
                border-radius: 0.5rem;
                border-bottom: 0.06rem solid #f2f2f2;
                justify-content: space-around;
                padding: 1rem 5rem;
                .header-item{
                    width: 33.3%;
                    cursor: pointer;
                    .header-img{
                        border-radius: 2.5rem;
                        width:4rem;height:4rem;
                        img{
                            width:1.81rem;height:1.81rem;
                        }
                    }
                    .header-select{
                        box-shadow: 0 0.125rem 0.75rem 0 rgba(0,0,0, 0.1);
                        
                    }
                    .header-img:hover{
                        box-shadow: 0 0.125rem 0.75rem 0 rgba(0,0,0, 0.1);
                    }
                    .text-select{
                        color: #D70C19 !important;
                    }
                    .text{
                        color: #333333;
                        margin-top: 0.5rem;
                    }
                }
            }
            .newsdynamics{
                margin-top: 4rem;
                width: 100%;
                height: 29.38rem;
                justify-content: space-between;
                .image-news{
                    width: 28.63rem;
                    height: 100%;
                    background: #fff;
                    border-radius: 0.5rem;
                    .inage-news{
                        cursor: pointer;
                        width: 28.75rem;
                        height: 20.63rem;
                    }
                }
                .right-news{
                    width: 45.38rem;
                    height: 100%;
                    justify-content: space-between;
                    .news{
                        cursor: pointer;
                        width: 100%;
                        height: 49%;
                        background-color: #fff;
                        border-radius: 0.5rem;
                    }
                }
            }
            .search{
                margin: 2rem 0 0;
            }
            .list{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                .type{
                    width: 24rem;
                    cursor: pointer;
                    height: 22.94rem;
                    margin-right: 1.5rem;
                    margin-bottom: 1.5rem;
                    border-radius: 0.5rem;
                    background-color: #fff;
                    .type-image{
                        width: 24rem;
                        height: 17.19rem;
                        background-size: 100% auto !important;
                        background-position: center !important;
                        background-repeat: no-repeat !important;
                    }
                    .type-image-nodata{
                        width: 24rem;
                        height: 17.19rem;
                        background-size: 50% auto !important;
                        background-position: center !important;
                        background-repeat: no-repeat !important;
                    }
                    
                    .text{
                        width: 100%;
                        height: 5.75rem;
                        border-radius: 0.5rem;
                        padding: 0 1rem;
                        .text-p{
                            text-align: left;
                            width: 100%;
                            margin: 0.5rem 0;
                        }
                    }
                }
                .type:nth-child(3n){margin-right:0}
                .type:hover{
                     box-shadow: 0 0.125rem 0.75rem 0 rgba(0,0,0,0.4);
                }
            }
            .pagination{
                width: 100%;
                flex-direction:row-reverse
            }
        }
    }
    .news-arrow{
        width: 1.25rem;
        height: 1.25rem;
        margin-right: 0.5rem;
    }
    .title{
        justify-content: space-between;
        align-items: self-start;
        padding: 1.3rem 1rem;
        width: 100%;
        .title-con{
            width: 100%;
            .p{
                width: 100%;
                font-weight: bold;
                font-size: 1rem;
                color: #333333;
            }
            .p-text{
                font-size: 0.88rem;
                color: #666666;
                padding-top: 1rem;
            }
        }
        .title-btn{
            width: 100%;
            text-align: right;
        }
    }
	/* 响应式布局 */
	@media (max-width: 1220px) {
		.newcenterSee .content {
		    width: 100%;
			padding: 0 1rem;
		}
		.newcenterSee .content .newsdynamics .right-news {
		    width: 61%;
			padding-left: 2%;
		}
		.newcenterSee .content .list .type{
		    width: 31%;
		    height: 20.94rem;
		    margin-right: 3.5%;
		}
		.newcenterSee .content .list .type:nth-child(3n){
			margin-right: 0;
		}
		.newcenterSee .content .list .type .type-image{
			width: 100%;
			height: 14.94rem;
		}
		.newcenterSee .content .list .type .type-image-nodata{
			width: 100%;
			height: 14.94rem;
		}
		
	}
	@media (max-width: 768px) {
		.newcenterSee .content .text-header {
		    padding: 1rem 1rem;
		}
		.newcenterSee .content .newsdynamics.row-center{
			display: block;
			height: auto;
		}
		.newcenterSee .content .newsdynamics .image-news {
		    width: 100%;
		    margin-bottom: 2rem;
		}
		.newcenterSee .content .newsdynamics .right-news{
		    width: 100%;
		    padding-left: 0;
		}   
		.newcenterSee .content .newsdynamics .right-news .news {
		    height: 15rem;
		    margin-bottom: 2rem;
		}
		.newcenterSee .content .newsdynamics .image-news .inage-news {
		    width: 100%;
		    height: auto;
		}
		.newcenterSee .content .list .type {
		    width: 48%;
		    margin-right: 4%;
			height: 22.94rem;
		}
		.newcenterSee .content .list .type:nth-child(3n){
			margin-right: 4%;
		}
		.newcenterSee .content .list .type:nth-child(2n){
			margin-right: 0;
		}
		.newcenterSee .content .list .type .type-image {
		        height: 16.94rem;
		}
		.newcenterSee .content .list .type .type-image-nodata {
		        height: 16.94rem;
		}
		 	
	}
	@media (max-width: 430px) {
		.newcenterSee .content .list .type{
		    height: 17.94rem;
		}    
		.newcenterSee .content .list .type .type-image {
		    height: 11.94rem;
		}	 
		.newcenterSee .content .list .type .type-image-nodata {
		    height: 11.94rem;
		}	    
	}
</style>